import React, { useState, useContext, useEffect } from "react";
import {
  Collapse,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
} from "reactstrap";
import { Button } from "../../../../../../../../../../components";

import DineroImg from "../../../../../../../../../../assets/dinero.png";
import AporteapvsinfinContext from "../../../../../../../../../../context/aporteapvsinfin/aportarapvsinfinContext";
const SelecionarAporteVoluntarioConFin = (props) => {
  const {
    prevView,
    nextView,
    dependencies,
    checkUseVoluntaryWithEnd,
    setCheckUseVoluntaryWithEnd,
    selectModeForApv,
    setselectModeForApv,
    amountRetire,
    despositInFondoHabitat,
    setDespositInFondoHabitat,
    amountForHabitat,
    setAmountForHabitat,
    fondoHabitat,
    setFondoHabitat,
    setAmountRetire,
    disclaimer,
    setDsiclaimer,
    setDsiclaimer2,
    autorization,
    setAutorization,
  } = props;

  const aporteapvsinfinContext = useContext(AporteapvsinfinContext);

  const [showModal, setShowModal] = useState(false);
  const [blockInputs, setBlockInputs] = useState(false);
  const [fondosArray, setFondosArray] = useState([0, 1, 2, 3]);
  const [error, setError] = useState("");
  const [validacionFondo, setValidacionFondo] = useState(false);
  const [showModalFatca, setShowModalFatca] = useState(false);
  const [showModalExitFatca, setShowModalExitFatca] = useState(false);
  const {
    datosAportarApvSinFin,
    obtenerDatosParaAportarApvSinFin,
    limpiarVariablesAportarApvSinFinParcial,
  } = aporteapvsinfinContext;

  const newRetire = amountRetire;

  const TotalRetire = checkUseVoluntaryWithEnd
    ? Number(amountRetire) +
      Number(dependencies.contribution.voluntary.replace(/,/g, ""))
    : Number(amountRetire);

  useEffect(() => {
    obtenerDatosParaAportarApvSinFin();
    return () => {
      limpiarVariablesAportarApvSinFinParcial();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (datosAportarApvSinFin) {
      if (datosAportarApvSinFin.r_validacion === "2") {
        setFondoHabitat(Number(datosAportarApvSinFin.r_fondo));
        setBlockInputs(false);
        setValidacionFondo(false);
      } else if (datosAportarApvSinFin.r_validacion === "1") {
        //   setFondoHabitat(Number(2));
        setBlockInputs(false);
        setValidacionFondo(true);
        const fondosResponse = datosAportarApvSinFin.r_fondo.split(",");
        fondosResponse.forEach((fondo) => {
          return Number(fondo);
        });
        setFondosArray(fondosResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosAportarApvSinFin]);

  const onContinue = () => {
    if (dependencies.meet_the_time_in_spp) {
      setAmountRetire(newRetire);
      setShowModalFatca(!showModalFatca);
    } else {
      setAmountRetire(newRetire);
      setShowModal(dependencies.meet_the_time_in_spp);
      nextView();
    }
  };
  const renderCheckInput = (fondo) => {
    return (
      <span className="oneChoice">
        <input
          type="radio"
          value={fondo}
          disabled={blockInputs}
          checked={fondoHabitat == fondo}
          className="radio-btn"
          id={`tfa_73${fondo}`}
          name="fondoHabitatoption"
          onClick={(e) => setFondoHabitat(Number(fondo))}
        />
        <label className="postRadioField" for={`tfa_73${fondo}`}>
          <span className="input-radio-faux"></span> {`Fondo ${fondo}`}
        </label>
      </span>
    );
  };
  const handleCheckAccept = () => {
    setShowModal(dependencies.meet_the_time_in_spp);
    setShowModalFatca(!showModalFatca);
    setDsiclaimer2(false);
  };

  const handleCheckExitAccept = () => {
    setShowModalFatca(!showModalFatca);
    setShowModalExitFatca(!showModalExitFatca);
    setDsiclaimer2(true);
  };
  const handleBackAccept = () => {
    setShowModalFatca(!showModalFatca);
    setShowModalExitFatca(!showModalExitFatca);
  };

  const handleContinue = () => {
    setShowModalExitFatca(!showModalExitFatca);
    nextView();
  };
  const toggle = () => {
    setShowModal(false);
    setDespositInFondoHabitat(false);
    setAmountForHabitat(0);
    setselectModeForApv(null);
    setShowModalFatca(!showModalFatca);
    if (!blockInputs) setFondoHabitat(-1);
  };
  function number_format(amount, decimals) {
    amount += ""; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, "")); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = "" + amount.toFixed(decimals);

    var amount_parts = amount.split("."),
      regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, "$1" + "," + "$2");

    return amount_parts.join(".");
  }
  const handleChangeAmountFondo = (e) => {
    const value = e.target.value;
    if (Number(value) < 0) {
      setAmountForHabitat(e.target.value);
      setError("El valor ingresado debe ser mayor a cero");
      return;
    }
    if (Number(TotalRetire) >= Number(value)) {
      if (Number(value) > Number(TotalRetire) * 0.98) {
        setAmountForHabitat(e.target.value);
        setError(
          "El monto ingresado es muy próximo al monto total, por lo que es posible que su solicitud pueda ser rechazada, por favor ingrese un monto menor o elija retiro total."
        );
        return;
      }

      if (Number(TotalRetire) >= 25000) {
        if (Number(value) >= 25000) {
          setAmountForHabitat(e.target.value);
          setDsiclaimer(true);
          setError("");
        } else {
          setAmountForHabitat(e.target.value);
          setDsiclaimer(false);
          setError("");
        }
      } else {
        setAmountForHabitat(e.target.value);
        setError("");
      }
    } else {
      setAmountForHabitat(e.target.value);
      setError("*El valor ingresado excede al total de retiro");
    }
  };
  const handleAutorization = () => {
    setAutorization(!autorization);
  };
  const handleCheckRetire = () => {
    setCheckUseVoluntaryWithEnd(!checkUseVoluntaryWithEnd);
  };

  return (
    <div className="select-amount-container">
      <h1 className="mb-3">Retiro de hasta 95.5%</h1>
      <p className="mb-2">
        También cuentas con aportes voluntarios con fin previsional. <br />
        Puedes agregarlos a tu retiro.
      </p>

      <div className="center-items">
        <div className="main-card-background reduce">
          <span className="text-card">Fondo de pensión</span>
          <p className="amount blue">
            S/ {dependencies.contribution.voluntary}
          </p>
          {/* {!dependencies.has_a_pension ? (
            <p className="discount-essalud">
              Retención del 4.5% para atención en EsSalud:{" "}
              <span>-S/{dependencies.contribution.voluntary}</span>
            </p>
          ) : null} */}
        </div>
      </div>
      {/* 
      <div className="center-items">
        <div className="main-card-background reduce">
          <span className="text-card">Fondo de pensión</span>
          <p className="amount blue mb-3">
            S/ {dependencies.contribution.voluntary}
          </p>
          {!dependencies.has_a_pension ? (
            <p className="discount-essalud">
              Retención del 4.5% para atención en EsSalud:{" "}
              <span>-S/{dependencies.contribution.voluntary}</span>
            </p>
          ) : null}
        </div>
      </div> */}

      <h3 className="mt-4">A CONTINUACIÓN, INDÍCANOS SI DESEAS RETIRARLOS:</h3>

      <div className="amount-check-container mt-4">
        <div className={`check-amount-content`}>
          <span className="oneChoice">
            <input
              type="radio"
              value={0}
              checked={checkUseVoluntaryWithEnd}
              className="radio-btn"
              id="tfa_711"
              name="select_mode"
              disabled={
                Number(
                  props.dependencies.contribution.voluntary.replace(/,/g, "")
                ) !== 0 &&
                Number(
                  props.dependencies.contribution.obligatory.replace(/,/g, "")
                ) === 0
              }
              onClick={handleCheckRetire}
            />
            <label className="postRadioField" for="tfa_711">
              <span className="input-radio-faux"></span>
            </label>
          </span>
          <div className="complete">
            <label htmlFor="tfa_711">
              <span
                className={`percent ${
                  checkUseVoluntaryWithEnd ? "" : "disable"
                }`}
              >
                Monto total
              </span>
              <br />
              <span
                className={`amount ${
                  checkUseVoluntaryWithEnd ? "" : "disable"
                }`}
              >
                (S/ {dependencies.contribution.voluntary})
              </span>
            </label>
          </div>
        </div>
      </div>

      <div className="container-legal-buttons">
        <Button className="mb-4 btn-modal-secondary" onClick={() => prevView()}>
          Volver
        </Button>
        <Button className={"mb-4 btn-modal-primary"} onClick={onContinue}>
          Continuar
        </Button>
      </div>
      <Modal
        isOpen={showModalFatca}
        toggle={toggle}
        backdrop={"static"}
        className="retiro-95 btn-close"
      >
        <ModalHeader toggle={toggle} className="title-fatca-modal">
          Antes de continuar con tu solicitud necesitamos que nos confirmes si
          tienes una residencia en Estados Unidos (US Person)
        </ModalHeader>
        <ModalBody>
          <Row className="pb-3 body-fatca">
            <Col md={12}>
              <p>Lo tendrías en cualquiera de estos 4 casos:</p>
            </Col>
            <Col md={6}>
              <ul>
                <li>Naciste en EE.UU.</li>
                <li>Tienes obligaciones fiscales en EE.UU. o en otro país</li>
              </ul>
            </Col>
            <Col md={6}>
              <ul>
                <li>Tienes nacionalidad americana</li>
                <li>Tienes permiso de residir en EE.UU.</li>
              </ul>
            </Col>
            <Col md={12}>
              <Label check className="input_disclaimer_debito">
                Con carácter de Declaración Jurada manifiesto que soy una “US
                Person” es decir, soy ciudadano estadounidense, residente
                estadounidense o cuento con residencia fiscal con más de 180
                días de permanencia en los Estados Unidos. Que la información
                declarada es exacta, verdadera y eximo a AFP Habitat de toda
                responsabilidad que se derive por la información errónea, falsa
                o inexacta que yo hubiere proporcionado.{" "}
              </Label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="container-reja-buttons">
            <Button
              className="mb-4 btn-modal-secondary"
              onClick={handleCheckAccept}
            >
              No tengo
            </Button>
            <Button
              className="mb-4 btn-modal-primary"
              onClick={handleCheckExitAccept}
            >
              Si tengo
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModalExitFatca}
        toggle={toggle}
        backdrop={"static"}
        className="retiro-95"
      >
        <ModalHeader toggle={toggle}>
          <p>
            Estimado afiliado,
            <br /> Al tener la condición de US Person, no podrás dejar un monto
            parcial o total en Fondo Libre Habitat (Aporte voluntario sin fin
            previsional).
            <br /> Finalizando su trámite lo invitamos a invertir en Fondo Libre
            Habitat, para mayor información has clic{" "}
            <a href="https://www.afphabitat.com.pe/aporte-voluntario/#cuanto-cuesta-el-servicio">
              aquí
            </a>
            .
          </p>
        </ModalHeader>
        <ModalBody></ModalBody>
        <ModalFooter>
          <div className="container-reja-buttons">
            <Button
              className="mb-4 btn-modal-secondary"
              onClick={handleBackAccept}
            >
              Volver
            </Button>
            <Button className="mb-4 btn-modal-primary" onClick={handleContinue}>
              Continuar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModalFatca}
        toggle={toggle}
        backdrop={"static"}
        className="retiro-95 btn-close"
      >
        <ModalHeader toggle={toggle} className="title-fatca-modal">
          Antes de continuar con tu solicitud necesitamos que nos confirmes si
          tienes una residencia en Estados Unidos (US Person)
        </ModalHeader>
        <ModalBody>
          <Row className="pb-3 body-fatca">
            <Col md={12}>
              <p>Lo tendrías en cualquiera de estos 4 casos:</p>
            </Col>
            <Col md={6}>
              <ul>
                <li>Naciste en EE.UU.</li>
                <li>Tienes obligaciones fiscales en EE.UU. o en otro país</li>
              </ul>
            </Col>
            <Col md={6}>
              <ul>
                <li>Tienes nacionalidad americana</li>
                <li>Tienes permiso de residir en EE.UU.</li>
              </ul>
            </Col>
            <Col md={12}>
              <Label check className="input_disclaimer_debito">
                Con carácter de Declaración Jurada manifiesto que soy una “US
                Person” es decir, soy ciudadano estadounidense, residente
                estadounidense o cuento con residencia fiscal con más de 180
                días de permanencia en los Estados Unidos. Que la información
                declarada es exacta, verdadera y eximo a AFP Habitat de toda
                responsabilidad que se derive por la información errónea, falsa
                o inexacta que yo hubiere proporcionado.{" "}
              </Label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="container-reja-buttons">
            <Button
              className="mb-4 btn-modal-secondary"
              onClick={handleCheckAccept}
            >
              No tengo
            </Button>
            <Button
              className="mb-4 btn-modal-primary"
              onClick={handleCheckExitAccept}
            >
              Si tengo
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModalExitFatca}
        toggle={toggle}
        backdrop={"static"}
        className="retiro-95"
      >
        <ModalHeader toggle={toggle}>
          <p>
            Estimado afiliado,
            <br /> Al tener la condición de US Person, no podrás dejar un monto
            parcial o total en Fondo Libre Habitat (Aporte voluntario sin fin
            previsional).
            <br /> Finalizando su trámite lo invitamos a invertir en Fondo Libre
            Habitat, para mayor información has clic{" "}
            <a href="https://www.afphabitat.com.pe/aporte-voluntario/#cuanto-cuesta-el-servicio">
              aquí
            </a>
            .
          </p>
        </ModalHeader>
        <ModalBody></ModalBody>
        <ModalFooter>
          <div className="container-reja-buttons">
            <Button
              className="mb-4 btn-modal-secondary"
              onClick={handleBackAccept}
            >
              Volver
            </Button>
            <Button className="mb-4 btn-modal-primary" onClick={handleContinue}>
              Continuar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        backdrop={"static"}
        className="retiro-95"
      >
        <ModalHeader toggle={toggle}>
          <h6>Tu monto a retirar es</h6>
          <h3>S/ {number_format(Number(TotalRetire).toFixed(2), 2)}</h3>
        </ModalHeader>
        <ModalBody>
          <div className="my-money">
            <h6> ¿Tienes metas como un auto, una casa o más?</h6>
          </div>
          <p className="info-retiro-95 mt-3">
            Si deseas, puedes invertir una parte o todo este monto en{" "}
            <strong>Fondo Libre Habitat</strong>, un producto sin montos mínimos
            y sin límite de operaciones para perseguir metas ajenas a la
            jubilación. <strong>¡Haz aportes y retiros cuando quieras!</strong>
            <br /> <br />
            Si deseas información sobre los costos de este servicio puedes
            revisarlos{" "}
            <a
              href="https://www.afphabitat.com.pe/aporte-voluntario/#cuanto-es-la-comision"
              target="_blank"
              rel="noopener noreferrer"
            >
              aquí
            </a>
          </p>

          <div className="check-container mt-2">
            <span className="oneChoice">
              <input
                type="radio"
                value={1}
                checked={despositInFondoHabitat}
                className="radio-btn"
                id="tfa_701"
                name="despositInFondoHabitat"
                onClick={(e) => {
                  setDespositInFondoHabitat(!despositInFondoHabitat);

                  // if (!blockInputs) setFondoHabitat(-1);
                }}
              />
              <label className="postRadioField" id="tfa_701" for="tfa_701">
                <span className="input-radio-faux"></span>
              </label>
            </span>
            <label className="postRadioField" id="tfa_701" for="tfa_701">
              <p>Sí, deseo aportar a Fondo Libre Habitat</p>
            </label>
          </div>

          <Collapse
            isOpen={despositInFondoHabitat}
            className="collapse-fondo-habitat"
          >
            <div className="content-inputs" style={{ width: "100%" }}>
              <div className="option-free-fund mt-2 mb-2">
                <div className="amount-check-container">
                  <div className={`check-amount-content`}>
                    <span className="oneChoice">
                      <input
                        type="radio"
                        value={0}
                        checked={selectModeForApv === true}
                        className="radio-btn"
                        id="tfa_741"
                        name="select_mode_for_apv"
                        onClick={(e) => {
                          setselectModeForApv(true);
                          setAmountForHabitat(Number(TotalRetire));
                          //setAmount('');
                        }}
                      />
                      <label className="postRadioField" for="tfa_741">
                        <span className="input-radio-faux"></span>
                      </label>
                    </span>
                    <div className="complete">
                      <span
                        className={`percent ${
                          selectModeForApv === true ? "" : "disable"
                        }`}
                      >
                        Monto total
                      </span>
                      <br />
                      <span
                        className={`amount ${
                          selectModeForApv === true ? "" : "disable"
                        }`}
                      >
                        (S/ {number_format(Number(TotalRetire).toFixed(2), 2)})
                      </span>
                    </div>
                  </div>

                  <div className={`check-amount-content`}>
                    <span className="oneChoice">
                      <input
                        type="radio"
                        value={1}
                        checked={selectModeForApv === false}
                        className="radio-btn"
                        id="tfa_751"
                        name="select_mode_for_apv"
                        onClick={(e) => {
                          setAmountForHabitat("");
                          setselectModeForApv(false);
                          //setAmount('');
                        }}
                      />
                      <label className="postRadioField" for="tfa_751">
                        <span className="input-radio-faux"></span>
                      </label>
                    </span>
                    <div className="complete">
                      <span
                        className={`percent ${
                          selectModeForApv === false ? "" : "disable"
                        }`}
                      >
                        Indicar monto <br /> parcial
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Collapse isOpen={selectModeForApv === false}>
                <div className="amount-fondo-container mb-2">
                  <h4>Digite su monto</h4>
                  <div className="input-field">
                    <Input
                      type="number"
                      name="fondoHabitat"
                      id="fondoHabitat"
                      placeholder="S/. 00.00"
                      value={amountForHabitat}
                      onChange={handleChangeAmountFondo}
                    />
                    <img src={DineroImg} alt="dinero" />
                  </div>
                  {error === "" ? null : <p className="error-fondo">{error}</p>}
                </div>
              </Collapse>
              {disclaimer && (
                <div className="check-container mt-2">
                  <span className="oneChoice autorizacion-check">
                    <input
                      type="radio"
                      value={1}
                      checked={autorization}
                      className="radio-btn"
                      id="tfa_702"
                      name="autorization"
                      onClick={handleAutorization}
                    />
                    <label
                      className="postRadioField"
                      id="tfa_702"
                      for="tfa_702"
                    >
                      <span className="input-radio-faux"></span>
                    </label>
                  </span>
                  <p className="autorization-disclaimer">
                    Con carácter de Declaración Jurada, manifiesto que los
                    recursos no provienen de ninguna actividad ilícita y que no
                    se efectuarán transacciones destinadas a tales actividades o
                    a favor de personas relacionadas a las mismas. Asimismo, que
                    el importe a abonar en la cuenta de aportes voluntarios sin
                    fin previsional proviene de actividades lícitas y tiene como
                    origen mis aportes previsionales de mi fondo de pensión. Por
                    último, la información consignada en la presente Solicitud
                    de Abono para Aportes Voluntarios Sin Fin es exacta y
                    verdadera, y eximo a AFP Habitat de toda responsabilidad que
                    se derive por información errónea, falsa o inexacta que yo
                    hubiere proporcionado.
                  </p>
                </div>
              )}

              {validacionFondo === false ? (
                <div className="content-fondo">
                  <p className="text-fondo">
                    Verificamos que ya tienes una cuenta en Fondo Libre Habitat.
                  </p>
                  <p className="text-fondo">
                    Su aporte se realizará en el Fondo {fondoHabitat} que
                    previamente tenías registrado.
                  </p>
                </div>
              ) : (
                <div>
                  {" "}
                  <h3 className="mt-4">Selecciona el fondo a invertir</h3>
                  <div className="fondos-container">
                    {fondosArray.map((fondo) => renderCheckInput(fondo))}
                  </div>
                </div>
              )}
              {validacionFondo === false ? null : (
                <Collapse isOpen={fondoHabitat !== -1}>
                  <div className="fondo-detail mt-4">
                    <h3>
                      {fondoHabitat === 1
                        ? "Fondo tipo 1 - Preservación del capital"
                        : fondoHabitat === 2
                        ? "Fondo tipo 2 - Fondo mixto"
                        : fondoHabitat === 3
                        ? "Fondo tipo 3 - Fondo de apreciación de capital"
                        : "Fondo tipo 0 - Protección del Capital"}
                    </h3>
                    {fondoHabitat === 1 ? (
                      <div>
                        <ul className="list-fondo">
                          <li>
                            Tiene un comportamiento estable, con un bajo riesgo
                            y expectativas de rentabilidad.{" "}
                          </li>
                          <li>
                            Solo el 10% del fondo se invierte en renta variable,
                            el resto en renta fija.
                          </li>
                        </ul>
                      </div>
                    ) : fondoHabitat === 2 ? (
                      <div>
                        <ul className="list-fondo">
                          <li>
                            Su nivel de riesgo es moderado, así como sus
                            expectativas de crecimiento.{" "}
                          </li>
                          <li>
                            Hasta el 45% del fondo se invierte en renta
                            variable, el resto en renta fija.
                          </li>
                        </ul>
                      </div>
                    ) : fondoHabitat === 3 ? (
                      <div>
                        <ul className="list-fondo">
                          <li>
                            Se enfoca en inversiones de mayor riesgo, con mayor
                            potencial de rentabilidad.{" "}
                          </li>
                          <li>
                            Hasta el 80% del fondo se invierte en renta
                            variable, el resto en renta fija.{" "}
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <ul className="list-fondo">
                          <li>
                            Este fondo busca proteger el capital ahorrado frente
                            a fluctuaciones del mercado mientras el afiliado se
                            encuentra en proceso de jubilación y es de carácter
                            obligatorio a menos que se exprese la voluntad de
                            asignar el fondo al tipo 1 o tipo 2.{" "}
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </Collapse>
              )}
            </div>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <div className="container-legal-buttons">
            <Button className="mb-4 btn-modal-secondary" onClick={toggle}>
              Volver
            </Button>
            <Button
              className="mb-4 btn-modal-primary"
              onClick={nextView}
              disabled={
                (despositInFondoHabitat &&
                  (error !== "" ||
                    fondoHabitat === -1 ||
                    amountForHabitat === "" ||
                    amountForHabitat === "0" ||
                    amountForHabitat === 0)) ||
                (despositInFondoHabitat &&
                  (error !== "" ||
                    fondoHabitat === -1 ||
                    amountForHabitat === "" ||
                    amountForHabitat === "0" ||
                    amountForHabitat === 0 ||
                    (disclaimer && !autorization)))
              }
            >
              Continuar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SelecionarAporteVoluntarioConFin;
