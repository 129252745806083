import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";

import {
  Collapse,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { Button } from "../../../../../../../../../../components";
import invertImg from "../../../../../../../../../../assets/icons/image 29.svg";
import minImg from "../../../../../../../../../../assets/icons/image 29-1.svg";
import limImg from "../../../../../../../../../../assets/icons/image 29-2.svg";
import fondo1Img from "../../../../../../../../../../assets/icons/c.svg";
import fondo2Img from "../../../../../../../../../../assets/icons/c-1.svg";
import fondo3Img from "../../../../../../../../../../assets/icons/c-2.svg";
import fondo0Img from "../../../../../../../../../../assets/icons/c-3.svg";
import AporteapvsinfinContext from "../../../../../../../../../../context/aporteapvsinfin/aportarapvsinfinContext";
import AuthContext from "../../../../../../../../../../context/autenticacion/authContext";
import Loading from "../../../../../../../../../../components/Loading/index.jsx";
import "./index.css";
const Body = styled.div`
  ${(props) => props.theme.tipography.body1};
  // padding: ${(props) => props.theme.spacing(4, 0)};
  text-align: center;
  & h2 {
    ${(props) => props.theme.tipography.h4};
    font-weight: 400;
    margin-bottom: 8px;
    margin-top: 16px;
    & .tooltip-apv .tooltiptext {
      width: 20em;
      text-align: left;
      ${(props) => props.theme.tipography.subtitle2};
      font-weight: 400;
      right: -7em;
    }
  }
  & h2.title-modal-fondo {
    ${(props) => props.theme.tipography.h4};
    font-weight: 700;
    margin-top: -24px;
  }
  & p:second-child {
    font-weight: 600;
  }
  & p {
    ${(props) => props.theme.tipography.body2};
    font-weight: 400;
    margin-bottom: 0;
    &.b-description {
      min-width: 300px;
      margin: 0 auto;
      width: 100%;
      max-width: 570px;
    }
  }
  & i {
    color: #2f6d95;
  }
  & .content-btn {
    justify-content: center;
    & button {
      margin-left: 16px;

      @media only screen and (max-width: 600px) {
        padding: 8px 16px !important;
      }
    }
  }
`;

const Message = styled.div`
  align-items: center;
  border-radius: ${(props) => props.theme.spacing(2)};
  background: ${(props) => props.theme.palette.alert.info};
  // display: flex;
  margin-top: 0;
  padding: ${(props) => props.theme.spacing(2)};
  // min-width: 300px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 570px;
  & p:first-child {
    font-weight: 600;
  }
  & img {
    margin-right: ${(props) => props.theme.spacing(1)};
  }
`;
const ContentFondo = styled.div`
  ul {
    list-style: none;
  }
  ul li {
    text-align: left;
    font-size: 14px;
  }
  ul li img {
    width: 45px;
  }
`;
const ContentFondoDetail = styled.div`
  ${(props) => props.theme.tipography.body2};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  & label {
    margin-bottom: 0;
  }
  & p {
    margin-bottom: 8px;
  }
`;
export default function SelecionarMonto(props) {
  const {
    prevView,
    nextView,
    selectMode,
    setSelectMode,
    selectModeForApv,
    setselectModeForApv,
    amount,
    setAmount,
    setAmounttotal,
    despositInFondoHabitat,
    setDespositInFondoHabitat,
    amountForHabitat,
    setAmountForHabitat,
    fondoHabitat,
    setFondoHabitat,
    dependencies,
    setAmountRetire,
    disclaimer,
    setDsiclaimer,
    setDsiclaimer2,
    autorization,
    setAutorization,
    selectModeDecision,
  } = props;

  const aporteapvsinfinContext = useContext(AporteapvsinfinContext);
  const {
    datosAportarApvSinFin,
    mensajeAlerta,
    obtenerDatosParaAportarApvSinFin,
    limpiarVariablesAportarApvSinFinParcial,
  } = aporteapvsinfinContext;
  const authContext = useContext(AuthContext);
  const { nombreUsuario } = authContext;
  const [showModal, setShowModal] = useState(false);

  const [error, setError] = useState("");
  const [errorParcial, setErrorParcial] = useState("");

  const [blockInputs, setBlockInputs] = useState(false);
  const [fondosArray, setFondosArray] = useState([0, 1, 2, 3]);
  const [validacionFondo, setValidacionFondo] = useState(false);
  const [showModalFondo, setShowModalFondo] = useState(false);
  const [showModalFatca, setShowModalFatca] = useState(false);
  const [showModalExitFatca, setShowModalExitFatca] = useState(false);

  const TotalPension = selectModeDecision
    ? Number(dependencies.contribution.voluntary.replace(/,/g, "")) === 0
      ? Number(dependencies.contribution.obligatory.replace(/,/g, ""))
      : Number(dependencies.contribution.obligatory.replace(/,/g, "")) +
        Number(dependencies.contribution.voluntary.replace(/,/g, ""))
    : Number(dependencies.contribution.obligatory.replace(/,/g, ""));
  const calcularRetencion = !dependencies.has_a_pension
    ? 0.045 * Number(TotalPension)
    : Number(TotalPension);
  const totalAmount = !dependencies.has_a_pension
    ? Number(TotalPension) - calcularRetencion.toFixed(2)
    : Number(TotalPension);
  const newRetire = selectMode ? totalAmount : amount;

  useEffect(() => {
    obtenerDatosParaAportarApvSinFin();
    return () => {
      limpiarVariablesAportarApvSinFinParcial();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (datosAportarApvSinFin) {
      if (datosAportarApvSinFin.r_validacion === "2") {
        setFondoHabitat(Number(datosAportarApvSinFin.r_fondo));
        setBlockInputs(false);
        setValidacionFondo(false);
      } else if (datosAportarApvSinFin.r_validacion === "1") {
        //   setFondoHabitat(Number(2));
        setBlockInputs(false);
        setValidacionFondo(true);
        const fondosResponse = datosAportarApvSinFin.r_fondo.split(",");
        fondosResponse.forEach((fondo) => {
          return Number(fondo);
        });
        setFondosArray(fondosResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosAportarApvSinFin]);

  const onContinue = () => {
    setAmounttotal(totalAmount.toFixed(2));

    setAmountRetire(newRetire);
    setShowModalFondo(!showModalFondo);
  };
  const renderCheckInput = (fondo) => {
    return (
      <Grid item xs={3} sm={3}>
        <label
          className={
            "btn-fondo" + (Number(fondo) === fondoHabitat ? " checked" : "")
          }
          onClick={(e) => setFondoHabitat(Number(fondo))}
        >
          <span className="input-radio-faux"></span> {`Fondo ${fondo}`}
        </label>
      </Grid>
    );
  };
  const handleCheckAccept = () => {
    setShowModal(dependencies.meet_the_time_in_spp);
    setShowModalFatca(!showModalFatca);
    setDsiclaimer2(false);
  };
  const handleAccetpFondo = () => {
    setShowModalFatca(!showModalFatca);
    setShowModalFondo(!showModalFondo);
    setDespositInFondoHabitat(true);
  };

  const handleExitFondo = () => {
    setShowModalFondo(!showModalFondo);
    setDespositInFondoHabitat(false);
    nextView();
  };
  const handleCheckExitAccept = () => {
    setShowModalFatca(!showModalFatca);
    setShowModalExitFatca(!showModalExitFatca);
    setDsiclaimer2(true);
  };
  const handleBackAccept = () => {
    setShowModalFatca(!showModalFatca);
    setShowModalExitFatca(!showModalExitFatca);
  };

  const handleContinue = () => {
    setShowModalExitFatca(!showModalExitFatca);
    setDespositInFondoHabitat(false);
    nextView();
  };
  const toggle = () => {
    setShowModal(false);
    setDespositInFondoHabitat(false);
    setAmountForHabitat(0);
    setselectModeForApv(null);
    setShowModalFatca(false);
    setShowModalFondo(false);
    if (!blockInputs) setFondoHabitat(-1);
  };

  const handleAutorization = () => {
    setAutorization(!autorization);
  };

  const noNegativos = ()=>{

  }

  const handleChangeAmount = (e) => {
    const value = e.target.value;
    if (value.includes('+') || value.includes('-')) {
      return;
    }
    if (Number(value) < 0) {
      setAmount(e.target.value);
      setErrorParcial("El valor ingresado debe ser mayor a cero");
      return;
    }
    if (Number(totalAmount) >= Number(value)) {
      if (Number(value) > Number(totalAmount) * 0.98) {
        setAmount(e.target.value);
        setErrorParcial(
          "El monto ingresado es muy próximo al monto total, por lo que es posible que su solicitud pueda ser rechazada, por favor ingrese un monto menor o elija retiro total."
        );
      } else {
        setAmount(e.target.value);
        setErrorParcial("");
      }
    } else {
      setAmount(e.target.value);
      setErrorParcial("*El valor ingresado excede al total de retiro");
    }
  };

  function number_format(amount, decimals) {
    amount += ""; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, "")); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = "" + amount.toFixed(decimals);

    var amount_parts = amount.split("."),
      regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, "$1" + "," + "$2");

    return amount_parts.join(".");
  }

  const handleChangeAmountFondo = (e) => {
    const value = e.target.value;
    if (Number(value) < 0) {
      setAmountForHabitat(e.target.value);
      setError("El valor ingresado debe ser mayor a cero");
      return;
    }
    if (Number(newRetire) >= Number(value)) {
      if (Number(value) > Number(newRetire) * 0.98) {
        setAmountForHabitat(e.target.value);
        setError(
          "El monto ingresado es muy próximo al monto total, por lo que es posible que su solicitud pueda ser rechazada, por favor ingrese un monto menor o elija retiro total."
        );
        return;
      }

      if (Number(newRetire) >= 25000) {
        if (Number(value) >= 25000) {
          setAmountForHabitat(e.target.value);
          setDsiclaimer(true);
          setError("");
        } else {
          setAmountForHabitat(e.target.value);
          setDsiclaimer(false);
          setError("");
        }
      } else {
        setAmountForHabitat(e.target.value);
        setError("");
      }
    } else {
      setAmountForHabitat(e.target.value);
      setError("*El valor ingresado excede al total de retiro");
    }
  };

  const resultBlock = !selectMode
    ? errorParcial === "" && Number(amount) !== 0
      ? ""
      : "disabled"
    : "";

  if (mensajeAlerta !== null) return <p></p>;
  if (datosAportarApvSinFin === null) return <Loading />;

  return (
    <div className="select-amount-container">
      <h1 className="mb-3">Retiro de hasta 95.5%</h1>
      <p className="mb-2">
        A continuación, te mostraremos el monto que podrás retirar:
      </p>

      <div className="center-items">
        <div className="main-card-background reduce">
          <span className="text-card">Fondo de Jubilación</span>
          <p className="amount blue">S/ {number_format(TotalPension, 2)}</p>
          {!dependencies.has_a_pension ? (
            <p className="discount-essalud">
              Retención del 4.5% para atención en EsSalud:{" "}
              <span>S/ -{calcularRetencion.toFixed(2)}</span>
            </p>
          ) : null}
        </div>
      </div>
      <div className="center-items">
        <div className="main-card-background">
          <span className="text-card">Saldo disponible para retiro</span>
          <p className="amount">
            S/ {number_format(Number(totalAmount).toFixed(2), 2)}
          </p>
        </div>
      </div>

      <h3 className="mt-1">Elige la opción a retirar 2:</h3>

      <div className="amount-check-container mt-3">
        <div className={`check-amount-content`}>
          <span className="oneChoice">
            <input
              type="radio"
              value={0}
              checked={selectMode}
              className="radio-btn"
              id="tfa_711"
              name="select_mode"
              onClick={(e) => {
                setSelectMode(true);
                setAmount("");
              }}
            />
            <label className="postRadioField" for="tfa_711">
              <span className="input-radio-faux"></span>
            </label>
          </span>
          <div className="complete">
            <label htmlFor="tfa_711">
              <span className={`percent ${selectMode ? "" : "disable"}`}>
                Monto total
              </span>
              <br />
              <span className={`amount ${selectMode ? "" : "disable"}`}>
                S/ {number_format(Number(totalAmount).toFixed(2), 2)}
              </span>
            </label>
          </div>
        </div>
        <div className={`check-amount-content`}>
          <span className="oneChoice">
            <input
              type="radio"
              value={1}
              checked={!selectMode}
              className="radio-btn"
              id="tfa_721"
              name="select_mode"
              onClick={(e) => setSelectMode(false)}
            />
            <label className="postRadioField" for="tfa_721">
              <span className="input-radio-faux"></span>
            </label>
          </span>
          <div className="partial">
            <label htmlFor="tfa_721">
              <span className={`detail-title ${!selectMode ? "" : "disable"}`}>
                Retiro parcial
              </span>
            </label>

            <Collapse isOpen={!selectMode} style={{ flexDirection: "column" }}>
              <div className="input-amount-container mt-2">
                <div className="label-field">
                  <span>S/</span>
                </div>
                <div className="input-field">
                  <Input
                    type="number"
                    name="amount"
                    value={amount}
                    id="amount"
                    placeholder="00.00"
                    onChange={handleChangeAmount}
                    onKeyPress={(event) => {
                      const keyCode = event.which || event.keyCode;
                      if (keyCode === 43 || keyCode === 45) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
              {errorParcial === "" ? null : (
                <p className="error-fondo">{errorParcial}</p>
              )}
            </Collapse>
          </div>
        </div>
      </div>

      <div className="advice-card">
        <div className="advice-card-info">
          <p>
            <strong>Recuerda que ... </strong>{" "}
          </p>
          <ul>
            <li>
              El saldo disponible para retiro es calculado a la fecha de hoy. Es
              decir, podría variar en lo que quede del proceso.
            </li>
            <li>
              Si tienes un proceso judicial que implique una retención de tu
              fondo, se aplicará dicho descuento.
            </li>
            <li>
              Si una parte del monto corresponde a transferencias del fondo bajo
              el convenio Perú – Chile, dicha parte no podrá ser retirada, sino
              usada para tramitar una pensión.
            </li>{" "}
          </ul>
        </div>
      </div>

      <div className="container-legal-buttons">
        <Button
          name="secondary"
          bgColor={"white"}
          className="mb-4"
          onClick={() => prevView()}
        >
          Volver
        </Button>
        <Button
          className={"mb-4 btn-modal-primary " + resultBlock}
          onClick={onContinue}
          disabled={resultBlock === "disabled"}
        >
          Continuar
        </Button>
      </div>
      <Modal
        isOpen={showModalFondo}
        toggle={toggle}
        backdrop={"static"}
        className="retiro-95 btn-close"
      >
        <ModalHeader
          toggle={toggle}
          className="title-fatca-modal"
        ></ModalHeader>
        <ModalBody>
          <Grid container p={0}>
            <Grid sm={6} lg={12}>
              <Body>
                <h2 className="title-modal-fondo">
                  {" "}
                  ¿Conoces Fondo libre Habitat?
                </h2>
                <p>
                  Es un producto que te permite invertir en los distintos tipos
                  de fondo de la AFP con el fin de hacer crecer tu inversión y{" "}
                  <strong>es de libre disposición</strong>.
                </p>
                <h2>Beneficios</h2>
                <ContentFondo>
                  <ul>
                    <li>
                      <img src={invertImg} />
                      Invierte y retira cuando quieras.
                    </li>
                    <li>
                      <img src={minImg} />
                      Sin montos mínimos.
                    </li>
                    <li>
                      <img src={limImg} />
                      Sin límites de operaciones.
                    </li>
                  </ul>
                </ContentFondo>
              </Body>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <div className="container-legal-fondo-buttons">
            <Button
              onClick={handleAccetpFondo}
              name="secondary"
              bgColor="white"
            >
              Deseo invertir
            </Button>
            <Button onClick={handleExitFondo}>Quizás en otro momento</Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModalFatca}
        toggle={toggle}
        backdrop={"static"}
        className="retiro-95 btn-close"
      >
        <ModalHeader
          toggle={toggle}
          className="title-fatca-modal"
        ></ModalHeader>
        <ModalBody>
          <Grid container p={0}>
            <Grid sm={6} lg={12}>
              <Body>
                <h2 className="title-modal-fondo">
                  ¿Tienes residencia fiscal fuera de Perú?{" "}
                  <i
                    id="tooltip-apv"
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                  <UncontrolledTooltip placement="top" target="tooltip-apv">
                    Se considera cuando: <br /> (1) Naciste en EE. UU. <br />
                    (2) Tienes obligaciones fiscales en EE. UU. o en otro país.{" "}
                    <br /> (3) Tienes nacionalidad americana. <br />
                    (4) Tienes permiso de residir en EE. UU.
                  </UncontrolledTooltip>
                </h2>

                <div className="container-reja-buttons">
                  <Button
                    onClick={handleCheckExitAccept}
                    name="secondary"
                    bgColor="white"
                  >
                    Sí tengo
                  </Button>
                  <Button onClick={handleCheckAccept}>No tengo</Button>
                </div>
              </Body>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          {" "}
          <Message className="box">
            <input type="checkbox" id="expanded" />
            <p check className="input_disclaimer_debito">
              Con carácter de Declaración Jurada manifiesto que soy una “US
              Person” es decir, soy ciudadano estadounidense, residente
              estadounidense o cuento con residencia fiscal con más de 180 días
              de permanencia en los Estados Unidos. Que la información declarada
              es exacta, verdadera y eximo a AFP Habitat de toda responsabilidad
              que se derive por la información errónea, falsa o inexacta que yo
              hubiere proporcionado.{" "}
            </p>
            <label for="expanded" role="button" className="button">
              Ver más
            </label>
          </Message>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModalExitFatca}
        toggle={toggle}
        backdrop={"static"}
        className="retiro-95"
      >
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody toggle={toggle}>
          <p className="message-text-fatca">
            {nombreUsuario}, al tener una residencia fuera de Perú, no podrás
            invertir en Fondo Libre Habitat. Al finalizar tu trámite te
            invitamos a ir a la opción{" "}
            <strong>Fondo Libre Habitat {">"} Invertir</strong>, para mayor
            información has clic{" "}
            <a
              href="https://www.afphabitat.com.pe/aporte-voluntario/#cuanto-cuesta-el-servicio"
              target="_blank"
              rel="noopener noreferrer"
            >
              aquí
            </a>
            .
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="container-legal-buttons">
            <Button
              name="secondary"
              bgColor="white"
              className="mb-4 btn-modal-secondary"
              onClick={handleBackAccept}
            >
              Volver
            </Button>
            <Button className="mb-4 btn-modal-primary" onClick={handleContinue}>
              Continuar trámite
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        backdrop={"static"}
        className="retiro-95"
      >
        <ModalHeader toggle={toggle} className="title-fatca-modal">
          Fondo Libre Habitat
        </ModalHeader>
        <ModalBody>
          <div className="my-money">
            <h6>Tu monto de retiro de jubilación es</h6>
            <h3>S/ {number_format(Number(newRetire).toFixed(2), 2)}</h3>
          </div>
          <Collapse
            isOpen={despositInFondoHabitat}
            className="collapse-fondo-habitat"
          >
            <div className="content-inputs" style={{ width: "100%" }}>
              <h6>¿Cuánto deseas invertir?</h6>
              <div className="option-free-fund mt-2 mb-2">
                <div className="amount-check-container">
                  <div className={`check-amount-content`}>
                    <span className="oneChoice">
                      <input
                        type="radio"
                        value={0}
                        checked={selectModeForApv === true}
                        className="radio-btn"
                        id="tfa_741"
                        name="select_mode_for_apv"
                        onClick={(e) => {
                          setselectModeForApv(true);
                          setAmountForHabitat(Number(newRetire));
                          //setAmount('');
                        }}
                      />
                      <label className="postRadioField" for="tfa_741">
                        <span className="input-radio-faux"></span>
                      </label>
                    </span>
                    <div className="complete">
                      <span
                        className={`percent ${
                          selectModeForApv === true ? "" : "disable"
                        }`}
                      >
                        Monto total
                      </span>
                      <br />
                      <span
                        className={`amount ${
                          selectModeForApv === true ? "" : "disable"
                        }`}
                      >
                        (S/ {number_format(Number(newRetire).toFixed(2), 2)})
                      </span>
                    </div>
                  </div>

                  <div className={`check-amount-content`}>
                    <span className="oneChoice">
                      <input
                        type="radio"
                        value={1}
                        checked={selectModeForApv === false}
                        className="radio-btn"
                        id="tfa_751"
                        name="select_mode_for_apv"
                        onClick={(e) => {
                          setAmountForHabitat("");
                          setselectModeForApv(false);
                          //setAmount('');
                        }}
                      />
                      <label className="postRadioField" for="tfa_751">
                        <span className="input-radio-faux"></span>
                      </label>
                    </span>
                    <div className="complete">
                      <span
                        className={`percent ${
                          selectModeForApv === false ? "" : "disable"
                        }`}
                      >
                        Monto parcial
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Collapse isOpen={selectModeForApv === false}>
                <div className="amount-fondo-container mb-2">
                  <h4>Digite su monto</h4>
                  <div className="input-field">
                    <Input
                      type="number"
                      name="fondoHabitat"
                      id="fondoHabitat"
                      placeholder="S/. 00.00"
                      value={amountForHabitat}
                      onChange={handleChangeAmountFondo}
                      onKeyPress={(event) => {
                        const keyCode = event.which || event.keyCode;
                        if (keyCode === 43 || keyCode === 45) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {error === "" ? null : <p className="error-fondo">{error}</p>}
                </div>
              </Collapse>
              {disclaimer && (
                <div className="check-container mt-2">
                  <span className="oneChoice autorizacion-check">
                    <input
                      type="radio"
                      value={1}
                      checked={autorization}
                      className="radio-btn"
                      id="tfa_702"
                      name="autorization"
                      onClick={handleAutorization}
                    />
                    <label
                      className="postRadioField"
                      id="tfa_702"
                      for="tfa_702"
                    >
                      <span className="input-radio-faux"></span>
                    </label>
                  </span>
                  <p className="autorization-disclaimer">
                    Con carácter de Declaración Jurada, manifiesto que los
                    recursos no provienen de ninguna actividad ilícita y que no
                    se efectuarán transacciones destinadas a tales actividades o
                    a favor de personas relacionadas a las mismas. Asimismo, que
                    el importe a abonar en la cuenta de aportes voluntarios sin
                    fin previsional proviene de actividades lícitas y tiene como
                    origen mis aportes previsionales de mi fondo de pensión. Por
                    último, la información consignada en la presente Solicitud
                    de Abono para Aportes Voluntarios Sin Fin es exacta y
                    verdadera, y eximo a AFP Habitat de toda responsabilidad que
                    se derive por información errónea, falsa o inexacta que yo
                    hubiere proporcionado.
                  </p>
                </div>
              )}

              {validacionFondo === false ? (
                <div className="content-fondo">
                  <p className="text-fondo">
                    Verificamos que ya tienes una cuenta en Fondo Libre Habitat.
                  </p>
                  <p className="text-fondo">
                    Su aporte se realizará en el Fondo {fondoHabitat} que
                    previamente tenías registrado.
                  </p>
                </div>
              ) : (
                <div>
                  {" "}
                  <h3 className="mt-2 mb-3">Seleciona tu tipo de fondo</h3>
                  <div className="fondos-container">
                    <Grid container spacing={1}>
                      {fondosArray.map((fondo) => renderCheckInput(fondo))}
                    </Grid>
                  </div>
                </div>
              )}
              {validacionFondo === false ? null : (
                <Collapse isOpen={fondoHabitat !== -1}>
                  <div className="fondo-detail mt-2">
                    <h3>
                      {fondoHabitat === 1
                        ? "Fondo tipo 1 - Conservador"
                        : fondoHabitat === 2
                        ? "Fondo tipo 2 - Moderado"
                        : fondoHabitat === 3
                        ? "Fondo tipo 3 - Arriesgado"
                        : "Fondo tipo 0 - Protección del capital"}
                    </h3>
                    {fondoHabitat === 1 ? (
                      <Grid container>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <img src={fondo1Img} />
                          </ContentFondoDetail>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <label>Potencial de rentabilidad:</label>
                            <p>Bajo</p>
                          </ContentFondoDetail>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <label>Nivel de riesgo:</label>
                            <p>Bajo</p>
                          </ContentFondoDetail>
                        </Grid>
                      </Grid>
                    ) : fondoHabitat === 2 ? (
                      <Grid container>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <img src={fondo2Img} />
                          </ContentFondoDetail>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <label>Potencial de rentabilidad:</label>
                            <p>Moderado</p>
                          </ContentFondoDetail>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <label>Nivel de riesgo:</label>
                            <p>Moderado</p>
                          </ContentFondoDetail>
                        </Grid>
                      </Grid>
                    ) : fondoHabitat === 3 ? (
                      <Grid container>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <img src={fondo3Img} />
                          </ContentFondoDetail>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <label>Potencial de rentabilidad:</label>
                            <p>Alto</p>
                          </ContentFondoDetail>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <label>Nivel de riesgo:</label>
                            <p>Alto</p>
                          </ContentFondoDetail>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <img src={fondo0Img} />
                          </ContentFondoDetail>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <label>Potencial de rentabilidad:</label>
                            <p>Muy bajo</p>
                          </ContentFondoDetail>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentFondoDetail>
                            <label>Nivel de riesgo:</label>
                            <p>Muy bajo</p>
                          </ContentFondoDetail>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Collapse>
              )}
            </div>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <div className="container-legal-buttons">
            <Button
              className="mb-4"
              name="secondary"
              bgColor="white"
              onClick={toggle}
            >
              Volver
            </Button>
            <Button
              className="mb-4 btn-modal-primary"
              onClick={nextView}
              disabled={
                error !== "" ||
                fondoHabitat === -1 ||
                amountForHabitat === "" ||
                amountForHabitat === "0" ||
                amountForHabitat === 0 ||
                error !== "" ||
                fondoHabitat === -1 ||
                amountForHabitat === "" ||
                amountForHabitat === "0" ||
                amountForHabitat === 0 ||
                (disclaimer && !autorization)
              }
            >
              Invertir y continuar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
