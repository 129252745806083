import React from "react";
import { montoFormat } from "../../../helper/amountFormat";
import * as Table from "./tableComponents";

const PartThree = ({
  saldoTotal: saltoTotal,
  comisionM,
  retiroFondo,
  saldoFinal,
}) => {
  return (
    <Table.Wrapper>
      <Table.Heading>Parte III: Movimientos al 10/03/2022</Table.Heading>
      <Table.Row>
        <Table.Label style={{ width: "34%" }}>Detalle</Table.Label>
        <Table.Label style={{ width: "33%" }}>Monto en soles</Table.Label>
        <Table.Label style={{ width: "33%" }} borderRight={true}>
          N.º de Cuotas
        </Table.Label>
      </Table.Row>
      <Table.Row>
        <Table.Detail
          style={{ width: "34%" }}
          textStyle={{ fontFamily: "Helvetica-Bold", textAlign: "left" }}
        >
          Saldo total
        </Table.Detail>
        <Table.Detail style={{ width: "33%" }}>
          {montoFormat(saltoTotal.monto)}
        </Table.Detail>
        <Table.Detail style={{ width: "33%" }} borderRight={true}>
          {saltoTotal.cuotas}
        </Table.Detail>
      </Table.Row>
      <Table.Row>
        <Table.Detail
          style={{ width: "34%" }}
          textStyle={{ fontFamily: "Helvetica-Bold", textAlign: "left" }}
        >
          Comisión por administración del fondo
        </Table.Detail>
        <Table.Detail style={{ width: "33%" }}>
          {montoFormat(comisionM.monto)}
        </Table.Detail>
        <Table.Detail style={{ width: "33%" }} borderRight={true}>
          {comisionM.cuotas}
        </Table.Detail>
      </Table.Row>
      <Table.Row>
        <Table.Detail
          style={{ width: "34%" }}
          textStyle={{ fontFamily: "Helvetica-Bold", textAlign: "left" }}
        >
          Retiro Fondo Libre Habitat
        </Table.Detail>
        <Table.Detail style={{ width: "33%" }}>
          {montoFormat(retiroFondo.monto)}
        </Table.Detail>
        <Table.Detail style={{ width: "33%" }} borderRight={true}>
          {retiroFondo.cuotas}
        </Table.Detail>
      </Table.Row>
      <Table.Row>
        <Table.Detail
          style={{ width: "34%" }}
          textStyle={{ fontFamily: "Helvetica-Bold", textAlign: "left" }}
        >
          Saldo final
        </Table.Detail>
        <Table.Detail style={{ width: "33%" }}>
          {montoFormat(saldoFinal.monto)}
        </Table.Detail>
        <Table.Detail style={{ width: "33%" }} borderRight={true}>
          {saldoFinal.cuotas}
        </Table.Detail>
      </Table.Row>
    </Table.Wrapper>
  );
};

export default PartThree;
